import $ from 'jquery'
import svg4everybody from 'svg4everybody'

import '@inttpl_public/JavaScript/SliderRandomImages'
import 'jquery-match-height/dist/jquery.matchHeight-min'
import '@intdiv_public/JavaScript/ResponsiveImages'

import 'bootstrap/js/src/button'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/modal'
import bsCustomFileInput from 'bs-custom-file-input'

import '@inttpl_private/TypeScript/ReadSpeaker'
import '@inttpl_private/TypeScript/Easteregg'
import '@inttpl_private/TypeScript/BackToTop'
import '@inttpl_private/TypeScript/Breadcrumbs'
import '@inttpl_private/TypeScript/PageIdInput'
import '@inttpl_private/TypeScript/Permalink'
import '@inttpl_private/TypeScript/Teaser'
import '@inttpl_private/TypeScript/Header'
import '@inttpl_public/JavaScript/Solr'

import '@ext_intcal/Resources/Public/JavaScript/LoadMoreEventsButton'

import '@ext/buildings/Resources/Private/TypeScript/BuildingLinks/BuildingLinksWidget'
import '@ext/europa/Resources/Private/JavaScripts/Questions'

import UrlHashChangeHandler from '@inttpl_private/TypeScript/UrlHashChangeHandler'
import initializeFoldables from '@ext/inlinetabs/Resources/Private/TypeScript/InitializeFoldables'
import { getSearchWordsFromUrl } from '@ext/domfilter/Resources/Private/TypeScript/SearchParams'

svg4everybody()
bsCustomFileInput.init()

const body = $('body')
const hashChangeHandler = new UrlHashChangeHandler()
const searchWords = getSearchWordsFromUrl()

$(initializeFoldables)
hashChangeHandler.addContentIdHandler(initializeFoldables)

let scrollableOverlayInstance = null
let tableDependencies = 1
let searchDepencies = 1
let importCount = 1
const importFinished = () => {
    importCount--
    if (importCount === 0) {
        allImportsFinished()
    }
}
const importStarted = () => {
    importCount++
}
const allImportsFinished = () => {
    // The initial hash processing is skipped when there are search words to prevent
    // conflicts with expanding tabs / foldables.
    $(() => hashChangeHandler.init(searchWords.length === 0))
}
const tableInitFinished = () => {
    tableDependencies--
    if (tableDependencies === 0) {
        initScrollableOverlays()
    }
}

body.buildingLinks()

const initializeGlossary = () => {
    const glossaryTermUrl = body.attr('data-tx-glossary-term-url')
    if (!glossaryTermUrl) {
        return
    }
    importStarted()
    import (/* webpackChunkName: "ufglossary-terms" */ '@ext/ufglossary/Resources/Private/TypeScript/GlossaryTooltipsWidget').then(
        () => {
            body.ufGlossaryTooltips({ url: glossaryTermUrl })
            importFinished()
        })
}

const initializeSearchHighlight = () => {
    searchDepencies--

    if (searchDepencies !== 0) {
        return
    }

    if (searchWords.length === 0) {
        return
    }

    importStarted()
    import(/* webpackChunkName: "search-script" */ '@inttpl_private/TypeScript/Search').then(({ default: Search }) => {
        const search = new Search(searchWords)
        search.initialize()
        importFinished()
    })
}

const initScrollableOverlays = () => {
    const scrollableOverlays = document.querySelectorAll('.scrollable-overlay-wrapper')
    if (!scrollableOverlays.length) {
        return
    }
    importStarted()
    import (/* webpackChunkName: "inttpl-scrollable-table-overlay" */ '@inttpl_private/TypeScript/ScrollableOverlays').then(
        ({ ScrollableOverlays }) => {
            new ScrollableOverlays(scrollableOverlays)
            importFinished()
        })
}

const expandableTextContainers = $('.expandable-text-container')
if (expandableTextContainers.length) {
    searchDepencies++
    importStarted()
    import(/* webpackChunkName: "expandable-text" */ '@ext/text_expander/Resources/Private/TypeScript/ExpandText').then(
        ({ default: ExpandText }) => {
            const expandText = new ExpandText(expandableTextContainers)

            body.one('expandable-text-initialized', () => {
                initializeGlossary()
                initializeSearchHighlight(expandText)
            })

            expandText.loadExpandableTexts()
            importFinished()
        })
}

if (!expandableTextContainers.length) {
    initializeGlossary()
}

const buildingOverviewMaps = $('.tx-buildings-overview-map')
if (buildingOverviewMaps.length) {
    importStarted()
    import (/* webpackChunkName: "buildings-overview-map" */ '@ext/buildings/Resources/Private/TypeScript/BuildingOverviewMap/OverviewMapWidget').then(
        ({ initialize }) => {
            initialize(buildingOverviewMaps, hashChangeHandler)
            importFinished()
        })
}

const initComplexTables = (parentElement) => {
    const relatedTables = $(parentElement).find('table.tx-complextable')
    if (!relatedTables.length) {
        return
    }
    relatedTables.complextable('init')

    if (scrollableOverlayInstance) {
        scrollableOverlayInstance.refresh()
    }
}

const complexTables = $('table.tx-complextable')
if (complexTables.length) {
    importStarted()
    tableDependencies++
    import (/* webpackChunkName: "complex-tables" */ '@ext/complextables/Resources/Private/TypeScript/ComplexTableWidget').then(
        () => {
            complexTables.complextable()

            // When the table was not visible before it needs to be initialized because
            // hidden tables are not scaled correctly!
            $('.card-foldable').one('shown.bs.collapse', (e) => {
                initComplexTables(e.target)
            })
            $(body).one('shown.bs.tab', function (e) {
                const target = $(e.target)
                if (target.attr('data-toggle') !== 'tab') {
                    return
                }
                initComplexTables(target.attr('href'))
            })
            tableInitFinished()
            importFinished()
        })
}

if ($('.tx-intdiv-lightbox-link, a.lightbox').length > 0 || body.data('teaser-page-url')) {
    importStarted()
    import (/* webpackChunkName: "fancybox" */ '@fancyapps/fancybox/dist/jquery.fancybox').then(() => {
        $.fancybox.defaults.buttons = [
            'zoom',
            // 'share',
            // 'slideShow',
            'fullScreen',
            'download',
            // 'thumbs',
            'close',
        ]
        importFinished()
    })
}

const slidersNeedsInit = (sliders, slidesSelector) => {
    if (!sliders.length) {
        return false
    }
    let initNeeded = false
    sliders.each(() => {
        if (sliders.find(slidesSelector).length > 1) {
            initNeeded = true
            return false
        }
    })
    return initNeeded
}

const contentSliders = $('.frame-type-ufslider_slider .slider')
const linkedImageSliders = $('.linked-image-carousel')
const calendarSliders = $('.calendar-carousel')
const newsSliders = $('.news-carousel')
const tabs = $('.tab-content')

if (
    slidersNeedsInit(contentSliders, '> .slide')
    || slidersNeedsInit(linkedImageSliders, '> .card')
    || slidersNeedsInit(newsSliders, '> .wrapped-link')
    // Calendar sliders always need initialization even if there is only a single slide because
    // there are multiple events displayed on a single slided an we have a flexible grid setting.
    || calendarSliders.length > 0
) {
    importStarted()
    import(/* webpackChunkName: "sliders" */'@inttpl_private/TypeScript/Sliders').then(({ Sliders }) => {
        const initAllSliders = function () {
            Sliders.initSlidersLinkedImage(linkedImageSliders)
            Sliders.initSlidersContent(contentSliders)
            Sliders.initSlidersCalendar(calendarSliders)
            Sliders.initSlidersNews(newsSliders)
        }
        $(() => {
            initAllSliders()

            window.addEventListener('beforeprint', () => {
                linkedImageSliders.slick('unslick')
                contentSliders.slick('unslick')
                calendarSliders.slick('unslick')
                newsSliders.slick('unslick')
            })

            window.addEventListener('afterprint', () => {
                initAllSliders()
            })
        })
        importFinished()
    })
}

$('.tx-intcal-event-show-backlink a').click((e) => {
    e.preventDefault()
    history.back()
})

const yesNoSurveys = $('.tx-ufsurvey-survey-container')
if (yesNoSurveys.length > 0) {
    importStarted()
    import(/* webpackChunkName: "yes-no-survey" */ '@ext/ufsurvey/Resources/Private/TypeScript/YesNoSurveyWidget').then(
        () => {
            yesNoSurveys.ufsurveyYesNo()
            importFinished()
        })
}

if ($('#tx-literature-show-apa-copy-link').length > 0) {
    importStarted()
    import(/* webpackChunkName: "apa-text-helper" */ '@ext/literature/Resources/Public/JavaScript/ApaTextHelper').then(
        importFinished)
}

const tableSorterTables = $('table.tablesorter')
if (tableSorterTables.length) {
    tableDependencies++
    importStarted()
    import(/* webpackChunkName: "tablesorter" */ '@inttpl_private/TypeScript/Tablesorter').then(() => {
        tableInitFinished()
        importFinished()
    })
}

tableInitFinished()

if (tabs.length > 0) {
    searchDepencies++
    importStarted()
    import(/* webpackChunkName: "initialize-tabs" */ '@ext/inlinetabs/Resources/Private/TypeScript/InlineTabs').then(({ default: InlineTabs }) => {
        const inlineTabs = new InlineTabs()
        inlineTabs.initialize()
        hashChangeHandler.addContentIdHandler(() => inlineTabs.activateSelectedTab())
        importFinished()

        initializeSearchHighlight()
    })
}

initializeSearchHighlight()

const domfilter = $('.tx-domfilter-container')
if (domfilter.length) {
    importStarted()
    import(/* webpackChunkName: "domfilter" */ '@ext/domfilter/Resources/Private/TypeScript/DomfilterWidget').then(() => {
        domfilter.domFilter()
        importFinished()
    })
}

const childcare = $('.tx-childcare')
if (childcare.length) {
    importStarted()
    import(/* webpackChunkName: "childcare" */ '@ext/childcare/Resources/Public/JavaScript/ChildCareFrontend').then(
        ({ default: initializeChildcare }) => {
            initializeChildcare()
            body.on('readspeakerClosed', initializeChildcare)
            importFinished()
        },
    )
}

const sympaMultisubscribeForm = $('.tx-sympa-multisubscribe-form')
if (sympaMultisubscribeForm.length) {
    importStarted()
    import(/* webpackChunkName: "sympa-multisubscribe-form" */ '@ext/sympa/Resources/Private/TypeScript/MultisubscribeFormWidget').then(
        function () {
            sympaMultisubscribeForm.sympaMultisubscribeForm()
            importFinished()
        })
}

const selectAllCheckboxes = $('.select-all-checkboxes')
if (selectAllCheckboxes.length) {
    importStarted()
    import(/* webpackChunkName: "select-all-checkboxes" */ '@inttpl_private/TypeScript/SelectAllCheckboxesWidget').then(
        function () {
            selectAllCheckboxes.ufutilsSelectAllCheckboxes()
            importFinished()
        })
}

// noinspection JSUnresolvedVariable
if (window.ufIntrojsConfig) {
    importStarted()
    import(/* webpackChunkName: "introjs-init" */ '@inttpl_public/JavaScript/Introjs').then(importFinished)
}

if ($('#copyright-form-wrapper').length) {
    importStarted()
    import(/* webpackChunkName: "copyright-form" */'@ext/uffilecopyright/Resources/Private/TypeScript/Copyright').then(
        importFinished)
}

// TODO: add check for screen width!
if ($('#navbarMobile').length && $('#navbarQuick').length) {
    importStarted()
    import(/* webpackChunkName: "mobile-menu" */ '@inttpl_public/JavaScript/MobileMenu').then(importFinished)
}

if ($('form.powermail_form').length) {
    importStarted()
    import(/* webpackChunkName: "powermail-cond" */ '@ext/powermail_cond/Resources/Public/JavaScript/PowermailCondition.min').then(
        importFinished)
}

const sitemaps = $('.frame-type-menu_sitemap, .frame-type-menu_sitemap_pages, .tx-ufutils-sitemap-feedback')
if (sitemaps.length) {
    importStarted()
    import(/* webpackChunkName: "foldable-sitemap" */ '@inttpl_private/TypeScript/FoldableSitemapWidget').then(function () {
        sitemaps.ufutilsFoldableSitemap()
        body.on('readspeakerClosed', function () {
            sitemaps.ufutilsFoldableSitemap('destroy')
            sitemaps.ufutilsFoldableSitemap()
        })
    })
}

$('.card-persis .display-horizontal .card-body').matchHeight()

importFinished()

const $window = $(window)
$(window).on('ajax.html.appended', () => {
    $window.trigger('responsive.image.reload')
})

// Throw resize event after switching a tab, so h5p iframes (and others) will load properly
$(window).on('shown.bs.tab', function (event) {
    if (document.querySelectorAll(event.target.getAttribute('href') + ' iframe').length > 0) {
        window.dispatchEvent(new Event('resize'));
    }
});
